import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form as RemixForm,
  FormProps,
  useActionData,
  useFetcher,
} from "@remix-run/react";
import { useEffect, useRef } from "react";
import { FieldValues, UseFormProps } from "react-hook-form";
import { RemixFormProvider, useRemixForm } from "remix-hook-form";
import type { Schema } from "zod";

type ServerErrors<T> = {
  [Property in keyof T]: string;
};

export type TFormProps<TFormValues extends FieldValues> = {
  onMethodInit?: (
    methods: ReturnType<typeof useRemixForm<TFormValues>>
  ) => void;
  children: (
    methods: ReturnType<typeof useRemixForm<TFormValues>>
  ) => React.ReactNode;
  useFormProps?: UseFormProps<TFormValues>;
  validationSchema?: Schema<TFormValues>;
  fieldErrors?: any[] | null;
  formError?: string | string[] | null | any;
  serverError?: ServerErrors<Partial<TFormValues>> | null;
  resetValues?: any | null;
  className?: string;
  id?: string;
  submitMode?: "onSubmit" | "onChange";
  resetAfterSuccess?: boolean;
} & Omit<FormProps, "children">;

export const Form = <
  TFormValues extends Record<string, any> = Record<string, any>,
>({
  onMethodInit,
  children,
  useFormProps,
  validationSchema,
  resetValues,
  className,
  id,
  submitMode = "onSubmit",
  resetAfterSuccess = true,
  action,
  ...formProps
}: TFormProps<TFormValues>) => {
  const methods = useRemixForm<TFormValues>({
    ...useFormProps,
    ...(validationSchema && { resolver: zodResolver(validationSchema) }),
  });

  const fetcher = useFetcher();
  const actionData = useActionData<{ ok: boolean }>();

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (actionData?.ok && resetAfterSuccess) {
      methods.reset();
    }
  }, [actionData]);

  useEffect(() => {
    if (onMethodInit) {
      onMethodInit(methods);
    }
  }, [onMethodInit, methods]);

  useEffect(() => {
    if (resetValues) {
      methods.reset(resetValues);
    }
  }, [resetValues, methods]);

  return (
    <RemixFormProvider {...methods}>
      <RemixForm
        method="post"
        noValidate
        id={id}
        action={action}
        onSubmit={submitMode === "onSubmit" ? methods.handleSubmit : undefined}
        onChange={submitMode === "onChange" ? methods.handleSubmit : undefined}
        {...formProps}
        ref={formRef}
        className={className}
      >
        {children(methods)}
      </RemixForm>
    </RemixFormProvider>
  );
};
